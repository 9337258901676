import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import './styles.scss';

const Navigation = () => (
  <div className="Navigation">
    <Container style={{ padding: "0px" }}>
      <Navbar expand="lg" variant="custom">
        <Navbar.Brand href="#home">
          <img src="/lutum-logo-negative.svg" height='48px' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto align-items-center" >
            {/*<Nav.Link className='custom-link' href="#our-technology">OUR TECHNOLOGY</Nav.Link>*/}
            <Nav.Link className='custom-link' href="#development-program">DEVELOPMENT PROGRAM</Nav.Link>
            {/* <Nav.Link className='custom-link' href="#team">TEAM</Nav.Link> */}
            {/* <Nav.Link className='custom-link' href="#advisors">ADVISORS</Nav.Link> */}
            <Button variant='outline-light' href="#contact">CONTACT</Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </div>
)

export default Navigation;