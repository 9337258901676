import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import './styles.scss';
import Button from 'react-bootstrap/Button';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Contact extends React.Component {
  state = {
    name: '',
    email: '',
    message: ''
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, name, message } = this.state;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", email, name, message })
    }).then(() => {
      this.setState({
        name: '',
        message: '',
        email: ''
      }, () => {
        alert('Thank you for your submission, we will be in touch!');
      });
    }).catch(error => alert(error));
  }

  render() {
    return (
      <section id="contact">
        <div className='Contact'>
          <form onSubmit={this.handleSubmit}>
            <Container>
              <Row>
                <Col xs='12'>
                  <h1>
                    Contact
                  </h1>
                </Col>
                <Col xs='12'>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      type='text'
                      required
                      placeholder="Name" />
                  </Form.Group>
                </Col>
                <Col xs='12'>
                  <Form.Group>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      type='text'
                      required
                      email
                      placeholder="Email Address" />
                  </Form.Group>
                </Col>
                <Col xs='12'>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      value={this.state.message}
                      onChange={(e) => this.setState({ message: e.target.value })}
                      as='textarea'
                      required
                      placeholder="Message" />
                  </Form.Group>
                </Col>
                <Col xs='12' style={{ textAlign: 'center', marginBottom: "80px", marginTop: '24px' }}>
                  <Button variant='secondary' size='lg' type="submit">Submit</Button>
                </Col>
                <Col xs='12' className='text-center'>
                  <small>Copyright © Lutum Technologies LLC. All rights reserved.</small>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </section>
    )
  }
}

export default Contact;