import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.scss';

const Hero = () => (
  <section id="home">
    <div className='Hero'>
      <Container>
        <Row>
          <Col xs='12' sm='12' md={{ span: 10, offset: 2 }} lg={{ span: 8, offset: 2 }} style={{ margin: "auto" }}>
            <h1>What is Lutum?</h1>
            <p>Lutum develops proprietary sensor and separation/capture technologies. We work with partner companies to identify specific target molecules or compounds of interest, develop sensors to detect them or composite materials to remove them, and work with partners to deploy our technology in the desired commercial markets.</p>
            <p>Our proprietary technology has clear advantages over competitors in cost, usability, sensitivity and breadth of applications.</p>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)

export default Hero;