import React from 'react';
import Navigation from './components/Navigation/Navigation';

import AppBackground from './components/AppBackground/AppBackground';
import Hero from './pages/Hero/Hero';
import OurTechnology from './pages/OurTechnology/OurTechnology';
import Applicable from './pages/Applicable/Applicable';
import DevelopmentProgram from './pages/DevelopmentProgram/DevelopmentProgram';
import Team from './pages/Team/Team';
import Advisors from './pages/Advisors/Advisors';
import Contact from './pages/Contact/Contact';
import ScrollNav from './components/ScrollNav/ScrollNav';

import './App.scss';

function App() {
  return (
    <div className="App">
      <AppBackground />
      <ScrollNav />
      <Navigation />
      <Hero />
      {/*<OurTechnology />*/}
      {/* <Applicable /> */}
      <DevelopmentProgram />
      {/* <Team /> */}
      {/* <Advisors /> */}
      <Contact />
    </div>
  );
}

export default App;
