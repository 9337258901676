import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.scss';

const DevelopmentProgram = () => (
  <div className='DevelopmentProgram' style={{ borderTop: "4px solid var(--primary)" }} id='development-program'>
    <Container>
      <Row>
        <Col xs='12'>
          <h1>
            Development Program
          </h1>
        </Col>
        <Col xs='12' sm='12' md="4">
          <div className='top-title'>
            Funded Discovery
          </div>
          <ul>
            <li>
              Partner identifies application specific target molecule of interest
            </li>
            <li>
              6-month average timeline to prototype
            </li>
            <li>
              Upfront cash fee
            </li>
          </ul>
        </Col>
        <Col xs='12' sm='12' md="4">
          <div className='top-title'>
            Sensor Transfer
          </div>
          <ul>
            <li>
              Collaboration with partner to integrate and optimize sensor for target commercial application
            </li>
            <li>
              Refine sensitivity and selectivity
            </li>
            <li>
              Develop proprietary software/algorithm
            </li>
            <li>
              Fee for service + exclusive licensing fee
            </li>
          </ul>
        </Col>
        <Col xs='12' sm='12' md="4">
          <div className='top-title'>
            Royalty/Licensing
          </div>
          <ul>
            <li>
              Partner gets exclusivity period for Lutum IP in desired commercial application
            </li>
            <li>
              Lutum guarantees fixed price production of target sensor chip during exclusivity period
            </li>
            <li>
              Contracted minimum chip sales or % royalty on commercial application of Lutum sensor technology
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </div>
)

export default DevelopmentProgram;